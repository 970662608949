.btn.apply-button,
.ok-button {
    display: block;
    min-width: 250px;
    margin: 0 0 0 auto;
}

.apply-button [data-icon] {
    margin-right: 5px;
}

.ok-button [data-icon] {
    margin-left: 5px;
}

.btn.next-button {
    display: block;
    min-width: 150px;
    margin: 0 0 0 auto;
}

.next-button [data-icon] {
    margin-left: 5px;
}

.btn.back-button,
.cancel-button {
    float: left;
    display: block;
    min-width: 150px;
    margin: 0 auto 0 0;
}

.back-button [data-icon] {
    margin-right: 5px;
}

.cancel-button [data-icon] {
    margin-left: 5px;
}

.button-row [data-icon] {
    margin-right: 5px;
}
