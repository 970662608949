@import '~bootstrap/scss/functions.scss';
@import '~bootstrap/scss/variables.scss';
@import '~bootstrap/scss/mixins.scss';

@import '../../../common/resources/styles/common.scss';

$skeleton-gray: #dcdfe2;
$skeleton-gray-dark: #b3b9c1;
$radius: 5px;

@mixin makeRect($value) {
    height: $value;
    width: $value;
    border-radius: $radius;
}

@mixin animatedGradient {
    position: relative;
    overflow: hidden;

    &::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        position: absolute;
        animation: wave 1.6s linear 0.5s infinite;
        transform: translateX(-100%);
        background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
    }
}

@keyframes wave {
    0% {
        transform: translateX(-100%);
    }
    60% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.rect {
    @include makeRect(70px);
    @include animatedGradient;
    padding: calc($grid-gutter-width / 2);
    background-color: $skeleton-gray;
    @include media-breakpoint-up(sm) {
        margin-left: 15px;
    }
}

.rect100 {
    @extend .rect;
    @include makeRect(100px);
    margin: calc($grid-gutter-width / 2) auto;
    border-radius: 0;
}

.text {
    @include animatedGradient;
    width: 100%;
    height: 10px;
    margin: 15px 0;
    background-color: $skeleton-gray;
}

.textBold {
    @extend .text;
    height: 18px;
}

.skeletonInput {
    width: 100%;
    height: $input-height;
    border-radius: $radius;
    border: 1px solid $skeleton-gray;
    margin-bottom: 15px;
}

.skeletonButton {
    @extend .skeletonInput;
    margin-top: 15px;
    background-color: $skeleton-gray-dark;
    border: none;
}

.skeletonJobDesc {
    margin-bottom: 15px;
}
