@import '~bootstrap/scss/functions.scss';
@import '~bootstrap/scss/variables.scss';

$accordion-white-smoke: #f5f5f5;
$accordion-grey: #dcdfe2;
$weight: 600;

.accordionContainer {
    background-color: $accordion-white-smoke;
    border-radius: 0.25rem;
    padding: $input-btn-padding-y $input-btn-padding-x;
    border: 1px solid $accordion-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: $input-height;
}

.text {
    font-size: 1.125rem;
    font-weight: $weight;
}

.icon {
    font-size: 2em;
}
