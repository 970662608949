.loading-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    cursor: pointer;
}
.loading-overlay .overlay-text {
    color: #f5f5f5;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 35px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
